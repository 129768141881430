import React, { useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { IMAGES } from '../../constants';
import { url } from '../../config';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import $ from 'jquery';

const Footer = () => {
    const { user, loading } = useSelector(state => state.user);
    
    const handleContactUsPopUp = (event) => {
        $("#popup-click-confirm").trigger('click');
    }

    useEffect(() => {
        // Meta Pixel Code
        window.fbq = function() {
            if (!window.fbq.callMethod) {
                window.fbq.queue.push(arguments);
            }
        };
        window.fbq.push = window.fbq;
        window.fbq.loaded = true;
        window.fbq.version = '2.0';
        window.fbq.queue = [];
        
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://connect.facebook.net/en_US/fbevents.js';
        document.body.appendChild(script);
        
        // Initialize the Pixel
        window.fbq('init', '1263481214863723');
        window.fbq('track', 'PageView');

        // Cleanup function to remove the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []); // Empty dependency array means this runs once when the component mounts

    return (
        <footer className="mt-3 pt-5 border-top">
            <div className="container">
                <div className="row">
                    <div className="col-md-3"><img src={IMAGES.WHITE_LOGO} alt="" /></div>
                    <div className="col-md-5 text-center px-0">
                        <ul>
                            <li><a href={url + "#whyus"}>
                                <FormattedMessage
                                    id="main.header.item1"
                                    defaultMessage="Why Cevaheer?"
                                />
                            </a></li>
                            <li><a href={url + "#pricings"}>
                                <FormattedMessage
                                    id="main.header.item2"
                                    defaultMessage="Pricing"
                                />
                            </a></li>
                            <li><a href={url + "#testimonails"}>
                                <FormattedMessage
                                    id="main.header.item3"
                                    defaultMessage="Testimonials"
                                />
                            </a></li>
                            <li><a href={url + "#nUsers"}>
                                <FormattedMessage
                                    id="main.header.item4"
                                    defaultMessage="Number of Members"
                                />
                            </a></li>
                        </ul>
                    </div>
                    <div className="col-md-4 text-right">
                        <ul>
                            <li><Link onClick={handleContactUsPopUp}>
                                <FormattedMessage
                                    id="main.header.contactsales"
                                    defaultMessage="Contact Sales"
                                />
                            </Link></li>

                            {
                                !user && !localStorage.getItem('isAuthenticated') &&
                                <Fragment>
                                    <li><Link to={url + '/login'}>
                                        <FormattedMessage
                                            id="main.header.signin"
                                            defaultMessage="Sign In"
                                        />
                                    </Link></li>
                                    <li><Link to={url + '/register'}>
                                        <FormattedMessage
                                            id="main.header.signup"
                                            defaultMessage="Sign Up"
                                        />
                                    </Link></li>
                                </Fragment>
                            }
                        </ul>
                    </div>
                </div>

                <div className="row btmRow">
                    <div className="col-md-12 text-center">
                        <ul>
                            <li>
                                <FormattedMessage
                                    id="main.header.copyright"
                                    defaultMessage="@2021"
                                />
                            </li>
                            <li><Link to={url + '/terms&conditions'}>
                                <FormattedMessage
                                    id="main.header.term"
                                    defaultMessage="Terms and condition"
                                />
                            </Link></li>
                            <li><Link to={url + '/privacy'}>
                                <FormattedMessage
                                    id="main.header.policy"
                                    defaultMessage="Privacy"
                                />
                            </Link></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;